import { template as template_205f591b0a504a26bbeb807f48192667 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_205f591b0a504a26bbeb807f48192667(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
