import { template as template_8e53276f52034d47ac820570ce8b9b4c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_8e53276f52034d47ac820570ce8b9b4c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
