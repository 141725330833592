import { template as template_84f7e7b985de424d92fac281b071fcfe } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_84f7e7b985de424d92fac281b071fcfe(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
