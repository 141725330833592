import { template as template_0550259de2b14f69ab09f9e52b039f6b } from "@ember/template-compiler";
const WelcomeHeader = template_0550259de2b14f69ab09f9e52b039f6b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
