import { template as template_6f6be4b273224b81a15f8e26f1cc20cc } from "@ember/template-compiler";
const FKControlMenuContainer = template_6f6be4b273224b81a15f8e26f1cc20cc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
